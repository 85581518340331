import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import message from '../views/message.vue'
import loading from '../views/load.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    name:'selectForm',
    component:()=>import("@/views/selectForm.vue"),
    meta:{title:"幼儿园入(出)接送"}
  },
  {
    path: '/message',
    name: 'message',
    component: message,
    meta: {
    	title: '幼儿信息'
    }
  },
  {
    path: '/load',
    name: 'load',
    component: loading,
    meta: {
    	title: '跳转'
    }
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/subFail',
    name: 'subFail',
    component: ()=>import("@/views/subFail.vue")
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
