import axios from "axios";
import Vue from "vue"
import Router from "vue-router"
import QS from "qs"
axios.defaults.baseURL = "/api";
axios.defaults.timeout = 300000;

 // axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
 // axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
 axios.defaults.headers.post["Content-Type"] =
   "application/x-www-form-urlencoded;charset=UTF-8"
axios.interceptors.request.use(
  (config) => {
    var token = window.sessionStorage.getItem("token");
	var admin=window.sessionStorage.getItem("admin");
    if (token) {
      config.headers.token = token;
	  config.headers.admin=admin
    }
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);


var uploadRequest = axios.create({
  baseURL: "/api",
  timeout: 300000,
  headers: { "Content-Type": "multipart/form-data" },
});
uploadRequest.interceptors.request.use(
  (config) => {
    var token = window.sessionStorage.getItem("token");
	var admin=window.sessionStorage.getItem("admin");
    if (token) {
      config.headers.token = token;
	  config.headers.admin=admin
    }
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);
var http = {
  get(url, params) {
    return new Promise((resolve, reject) => {
      //NProgress.start();
      axios
        .get(url, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200 && url === "/login") {
            window.sessionStorage.setItem("token", res.headers.token);
          }
          resolve(res.data);
        })
        .catch((err) => {

          reject(err.data);
        });
    });
  },
  post(url, params) {
    return new Promise((resolve, reject) => {

      axios
        .post(url, QS.stringify(params))
        .then((res) => {
			if(res.data.code==403||res.data.code==406){
						  //如果状态码为403则说明该用户被删除了，清除用户缓存,返回登录
						  sessionStorage.clear();
						   alert("用户已不存在，请重新登录其他用户")
			}
          resolve(res.data);
        })
        .catch((err) => {

          reject(err.data);
        });
    });
  },
  upload(url, file) {
    return new Promise((resolve, reject) => {
      uploadRequest
        .post(url, file)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {

          reject(err.data);
        });
    });
  },
  download(url) {
    let iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = url;
    iframe.onload = function() {
      document.body.removeChild(iframe);
    };
    document.body.appendChild(iframe);
  },
  install(Vue) {
    Vue.prototype.http = this;
    delete this.install;
  },
};
export default http;
