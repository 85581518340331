<template>
  <div>
    <div class="home" v-if="load">
      <div style="height: 10rem;width: 100vw;display: flex;">
        <div style="width: 20rem;text-align: left;margin-top: 2rem;margin-left: 1rem;color: white;">
          <span style="display: inline-block;width: 250px;font-size: 0.9rem;font-weight: 600;">幼儿园入(出)园接送信息登记</span>
          <span style="display: inline-block;width: 250px;font-size: 12px;transform: scale(0.7);margin-left: -2.4rem;">
            Kindergarten in (out) transfer information registration</span>
        </div>
        <img src="../assets/img/person.png" width="350rem" height="180rem"
          style="background-size: cover;margin-top:2.5rem;margin-left: -10rem;position: relative;z-index: -1;left: 1rem;" />
      </div>
      <div class="contain" style="">
        <div style="width: 85%;background-color: white;border-radius: 10px;padding-bottom: 3rem;">
          <!-- <div
          style="display: flex;justify-content: space-between;padding: 0 2rem;font-weight: bold;padding-top: 1rem;padding-bottom: 1rem;">
          <span style="color: #256ddb;font-size: 14px;">已选校区</span>
          <span style="font-size: 12px;">浙江第一幼儿园</span>
        </div> -->
          <div style="text-align: left;padding: 1rem 1rem;">
            <!-- 家长信息 -->
            <el-divider direction="vertical"></el-divider>
            <span>家长信息</span>
            <div style="padding-left: 1.5rem;">
              <div style="padding-top: 1rem;font-weight: bold;font-size: 12px;display: flex;">
                <div style="width: 180px;">家长姓名</div>
                <div style="padding-left: 0.5rem;width: 400px;">{{parentData.name}}</div>
              </div>
              <div style="padding-top: 0.5rem;font-weight: bold;font-size: 12px;display: flex;">
                <div style="width: 180px;">手机号码</div>
                <div style="padding-left: 0.5rem;width: 400px;">{{phone}}<i class="el-icon-view" style="color: #000FFF;margin-left: 1rem;" @click="hiddenPassword"></i></div>
              </div>
            </div>

            <!-- 幼儿信息 -->
            <div style="margin-top: 2rem;">
              <el-divider direction="vertical"></el-divider>
              <span>幼儿信息</span>
              <div class="box" v-for="(item,index) in childrenData">
                <div style="padding-left: 1.5rem;margin-top: 1rem;">
                  <div style="padding-top: 0.5rem;font-weight: bold;font-size: 12px;display: flex;">
                    <div style="width: 180px;">幼儿姓名</div>
                    <div style="padding-left: 0.5rem;width: 400px;">{{item.name}}</div>
                  </div>
                  <div style="padding-top: 0.5rem;font-weight: bold;font-size: 12px;display: flex;">
                    <div style="width: 180px;">幼儿班级</div>
                    <div style="padding-left: 0.5rem;width: 400px;">{{item.class}}</div>
                  </div>
                  <div style="padding-top: 0.5rem;font-weight: bold;font-size: 12px;display: flex;">
                    <div style="width: 180px;">就读学校</div>
                    <div style="padding-left: 0.5rem;width: 400px;">{{item.nursery}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-col>
        <div style="margin: 2rem 1.5rem;height: 40px;background: white;justify-content: center;
      display: flex;align-items: center;border-radius: 8px;font-weight: bold;color:#4481ff;">
          <span style="padding-bottom: 0.2rem;">{{outIn}}</span>
          <span style="padding-left: 1rem;">{{nowDate}}</span>
        </div>
      </el-col>
    </div>
    <div v-else>
      <div style="width: 100vw;display: flex;justify-content: center;align-items: center;">
        <img src="../assets/img/bg.png" width="100%" style="height: 200vh;position: absolute;right: 0.1rem;" />
        <img src="../assets/img/loading.gif" width="100%" style="position: absolute;top: 30%;" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        children: [],
        parent: [],
        nowDate: '',
        url: '',
        load: true,
        childrenData: '',
        parentData: '',
        currentHour:'',
        outIn:'',
        phone:''
      }
    },
    created() {
      this.childrenData = JSON.parse(window.sessionStorage.getItem("childrenData"))
      this.parentData = JSON.parse(window.sessionStorage.getItem("parentData"))
      this.url = this.$route.query.accessToken
      let parentPhone =this.parentData.phone
      let a = parentPhone.substr(7)
      let b = parentPhone.substr(0,3)
      this.phone=b+'***'+a
      this.getCurrentTime()
      this.getHour()
    },
    computed:{
      // phone:function(){
      //   let parentPhone =this.parentData.phone
      //   let a = parentPhone.substr(7)
      //   let b = parentPhone.substr(0,3)
      //   return b+'***'+a
      // }
    },
    methods: {
      //获取当前时分
      getHour() {
        var myDate = new Date(); //实例一个时间对象；
        this.currentHour = myDate.getHours() + "." + myDate.getMinutes()
        if(this.childrenData[0].operate==1){
          this.outIn="出园时间"
        }else if(this.childrenData[0].operate==2){
          this.outIn="入园时间"
        }else{
          this.outIn="关门时间"
        }
      },
      //显示完整手机号码
      hiddenPassword(){
        this.phone=this.parentData.phone
      },
      getCurrentTime() {
        //获取当前时间并打印
        var _this = this;
        let yy = new Date().getFullYear();
        let mm = new Date().getMonth() + 1;
        let dd = new Date().getDate();
        let hh = new Date().getHours();
        let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
        let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
        _this.nowDate = yy + '/' + mm + '/' + dd + ' ' + hh + ':' + mf + ':' + ss;
      },
      // async getUser() {
      //   const res = await this.http.post("/show/match", {
      //     accessToken: this.url
      //   })
      //   this.children = res.childrenData
      //   this.parent = res.parentData
      //   console.log(res)
      //   if (res.code == 400) {
      //     this.$router.push({ name: 'load' })
      //   } else if (res.code == 200) {
      //     this.load = false
      //     setTimeout(() => {
      //       this.load = true
      //     }, 3000)
      //   }
      // }
    }
  }
</script>

<style scoped="true">
  .box {
    overflow-y: scroll;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    max-height: 200px;
  }

  /deep/.el-divider--vertical {
    width: 0.3rem;
    background: #1890ff;
  }

  /deep/.el-form-item__label {
    color: #256ddb;
  }

  .home {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    background-image: url(../assets/img/bg.png);
    position: fixed;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
  }

  .contain {
    align-items: center;
    justify-content: center;
    display: flex;
  }
</style>
