<template>
  <div class="home">
    <div style="height: 10rem;width: 100vw;display: flex;">
      <div style="width: 20rem;text-align: left;margin-top: 2rem;margin-left: 1rem;color: white;">
        <span style="display: inline-block;width: 250px;font-size: 0.9rem;font-weight: 600;">幼儿园入(出)园接送信息登记</span>
        <span style="display: inline-block;width: 250px;font-size: 12px;transform: scale(0.7);margin-left: -2.4rem;">
          Kindergarten in (out) transfer information registration</span>
      </div>
      <img src="../assets/img/person.png" width="350rem" height="180rem"
        style="background-size: cover;margin-top:2.5rem;margin-left: -10rem;position: relative;z-index: -1;left: 1rem;" />
    </div>
    <div class="contain" style="">
      <div style="width: 85%;background-color: white;border-radius: 10px;padding-bottom: 3rem;">
        <div style="text-align: left;padding: 1rem 1rem;display: flex;justify-content: center;align-items: center;">
          <img src="../assets/img/email.png" style="width: 200px;height: 200px;"  />
          </div>
         <div style="font-size: 1.2rem;color: #3577f6;font-weight: bold;">因系统升级过渡中，暂未查找到您的家长身份，正在为您跳转旧版页面，请手动填报。</div>
         <div style="font-size: 1.2rem;color: #3577f6;font-weight: bold;margin-top: 0.5rem;">{{mintues}}秒后跳转旧版页面</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        mintues:"5"
      }
    },
    created() {
      setInterval(()=>{
        if(this.mintues==0){
          window.location.href = 'https://ding.cjfx.cn/f/xentejso';
        }else{
          this.mintues-=1
        }
      },1000)
    }
  }
</script>

<style scoped="true">
  .box {
    overflow-y: scroll;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    max-height: 200px;
  }

  /deep/.el-divider--vertical {
    width: 0.3rem;
    background: #1890ff;
  }

  /deep/.el-form-item__label {
    color: #256ddb;
  }

  .home {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    background-image: url(../assets/img/bg.png);
    position: fixed;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
  }

  .contain {
    align-items: center;
    justify-content: center;
    display: flex;
  }
</style>
