<template>
  <div class="home" style="height: 100%;">
    <div style="height: 10rem;width: 100vw;display: flex;">
      <div style="width: 20rem;text-align: left;margin-top: 3rem;margin-left: 1rem;color: white;">
        <span style="display: inline-block;width: 250px;font-size: 1rem;font-weight: 600;">幼儿园安全门禁系统</span>
        <span style="display: inline-block;width: 250px;font-size: 12px;transform: scale(0.7);margin-left: -2.4rem;">Kindergarten security access control system</span>
      </div>
      <img src="../assets/img/person.png" width="350rem" height="180rem" style="background-size: cover;margin-top:2.5rem;margin-left: -11rem;" />
    </div>
    <div class="contain" style="">
      <div class="form">
      <el-form :model="form" class="demo-form-inline mt-4 pl-6 pr-6" >
        <!-- row-1 -->
        <el-row>
          <el-col style="padding: 0 1rem;margin-top: 2rem;">
            <el-form-item label="请选择校区 :" >
              <el-select v-model="value" style="width: 10rem;" clearable placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col style="padding: 0 1rem;">
            <el-form-item label="请选择年级 :" >
              <el-select v-model="value" style="width: 10rem;" clearable placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col style="padding: 0 1rem;">
            <el-form-item label="请选择类型 :" >
               <el-radio v-model="radio" label="1">送孩子</el-radio>
                <el-radio v-model="radio" label="2">接孩子</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 2rem;">
          <el-col>
            <el-button type="primary" round style="width: 10rem;" @click="next()">下一步</el-button>
          </el-col>
        </el-row>
      </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src

  export default {
    name: 'Home',
    components: {},
    data() {
      return {
        form: {},
        radio:'',
        options: [{
                  value: '选项1',
                  label: '浙江第一幼儿园'
                }, {
                  value: '选项2',
                  label: '双皮奶'
                }, {
                  value: '选项3',
                  label: '蚵仔煎'
                }, {
                  value: '选项4',
                  label: '龙须面'
                }, {
                  value: '选项5',
                  label: '北京烤鸭'
                }],

        value: ''
      }
    },
    methods:{
      next(){
        this.$router.push({path:'/message'})
      }
    }
  }
</script>

<style scoped="true">
  /deep/.el-form-item__label{
   color: #256ddb;
   font-size: 12px;
  }
  .home{
    height: 100vh !important;
    width: 100vw;
   background-image: url(../assets/img/bg.png);
   overflow-x: hidden;
   position: fixed;
   margin-left: -0.5rem;
   margin-top: -0.5rem;
  }
  .contain {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .form {
    width: 80%;

    border: 1px solid #000000;
    border-radius: 10px;
    background: #ffffff;
    display: inline-block;
  }
</style>
